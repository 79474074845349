import { FC, MouseEvent } from "react"
import { useTranslation } from "next-i18next"
import { useTheme } from '@mui/material/styles'
import styles from './MenuLang.module.scss'
import { menuList } from "./menuList.config"
import { MenuLangPropsI } from "./interfaces"
import { IconWorld } from "@tabler/icons-react"
import Menu from "@mui/material/Menu"
import Cookies from 'universal-cookie'
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import MenuUI from "@lib-ui/molecules/MenuUI/MenuUI"

const MenuLang: FC<MenuLangPropsI> = ({
  anchorEl,
  handleClick
}: MenuLangPropsI) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const currentLang: string = i18n.language
  const openMenu = Boolean(anchorEl)

  const handle = (e: MouseEvent<HTMLElement>, locale: string) => {
    const cookies = new Cookies()
    cookies.set('lang', locale, { path: '/' })
    handleClick(e)
  }

  let menus = menuList(i18n, handle)
  let currentMenu = menus.find(e => e.locale === currentLang)
  menus = menus.filter(e => e.locale !== currentLang)
  if (!currentMenu) {
    currentMenu = menus.shift()
    if (!currentMenu) {
      return null
    }
  }

  return <>
    <ButtonUI
      className={styles['menu-button']}
      label={currentMenu.label}
      variant="text"
      // @ts-ignore
      color="primary"
      onClick={handleClick}
      startIcon={<IconWorld color={theme.palette.primary.main} stroke={1.25} />}
      data-testid="menu-account"
    />
    <Menu
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClick}
      MenuListProps={ {
        'aria-labelledby': 'account-button',
      } }
    >
      <MenuUI
        list={false}
        items={menus}
        hoverItems={ {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.tertiary.main,
        } }
      />
    </Menu>
  </>
}

export default MenuLang