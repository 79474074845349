import { i18n } from "i18next"
import { MouseEvent } from "react"

export const menuList = (i18next: i18n, handleClick: (e: MouseEvent<HTMLElement>, locale: string) => void) => [
  {
    'label': i18next.t('locale.en-GB'),
    'onClick': (e: MouseEvent<HTMLElement>) => {
      handleClick(e, 'en-US')
      i18next.changeLanguage('en-US')
    },
    // 'icon': <Flags.US title={i18next.t('menu.lang.US')} />,
    'locale': 'en-US'
  },
  // {
  //   'label': i18next.t('locale.en-GB'),
  //   'onClick': (e: MouseEvent<HTMLElement>) => {
  //     handleClick(e)
  //     i18next.changeLanguage('en-US')
  //   },
  //   'icon': <Flags.GB title={i18next.t('menu.lang.GB')} />,
  //   'locale': 'en-US'
  // },
  {
    'label': i18next.t('locale.fr-FR'),
    'onClick': (e: MouseEvent<HTMLElement>) => {
      handleClick(e, 'fr-FR')
      i18next.changeLanguage('fr-FR')
    },
    // 'icon': <Flags.FR title={i18next.t('menu.lang.FR')} />,
    'locale': 'fr-FR'
  }
]