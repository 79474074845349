import { FC, ReactNode } from "react"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import ListItemIcon from "@mui/material/ListItemIcon"
import { menuList } from "./menuList.config"
import styles from "./MenuSignIn.module.scss"
import { MenuSignInPropI } from "@saas-molecules/Menu/SignIn/interfaces"
import MenuUI from "@lib-ui/molecules/MenuUI/MenuUI"
import { useTheme } from "@mui/material/styles"

const MenuSignIn: FC<MenuSignInPropI> = ({
  variant = "row",
  withIcon = true
}) => {
  const theme = useTheme()
  const { i18n } = useTranslation("translation")

  const formatIcon = (icon?: ReactNode | string): ReactNode | null =>
    withIcon && icon ? <ListItemIcon data-testid="icon" className={styles['icon']}>{icon}</ListItemIcon> : null

  const formatLabel = (label?: ReactNode | string): ReactNode | null =>
    label ? <span data-testid="label" className={styles['label']}>{label}</span> : null

  const items = menuList(i18n, theme)

  return variant === 'row' ? (
    <div className={styles['menu']} data-testid="menu-header">
      {items && items.map(e => (
        <Link href={e.link!} locale={i18n.language} key={e.idx} className={styles['link-container']}>
          {formatIcon(e.icon)} {formatLabel(e.label)}
        </Link>
      ))}
    </div>
  ) : (
    <MenuUI
      items={items}
      className={styles['menu']}
    />
  )
}

export default MenuSignIn
