import styles from "./SignInHeader.module.scss"
import Link from "next/link"
import { IconLogin, IconLogout, IconMenu2 } from "@tabler/icons-react"
import Divider from "@mui/material/Divider"
import { useTranslation } from "next-i18next"
import { FC, KeyboardEvent, MouseEvent, useContext, useState } from "react"
import { LogoutHeaderPropsI } from "./interfaces"
import { signOut } from "next-auth/react"
import MenuLang from "@saas-molecules/Menu/Lang/MenuLang"
import Logo from "@saas-atoms/Logo/Logo"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { useUser } from "@saas-hooks"
import MenuSignIn from "@saas-molecules/Menu/SignIn/MenuSignIn"
import IconButton from "@mui/material/IconButton"
import Drawer from "@mui/material/Drawer"
import ReducerContext from "@saas-reducers/ReducerContext"

const SignInHeader: FC<LogoutHeaderPropsI> = ({
  login = true
}: LogoutHeaderPropsI) => {
  const { t, i18n } = useTranslation("translation")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { logout } = useContext(ReducerContext)

  const [state, setState] = useState<boolean>(false)

  const { user } = useUser()

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null)
  }

  const handlerSignOut = () => {
    signOut().catch(console.error)
    logout()
  }

  const buildLoginButton = () => {
    return user?.id ? (
      <ButtonUI label={t('menu.account.sign-out')} variant="text" onClick={handlerSignOut} startIcon={<IconLogout />} />
    ) : (
      <Link href={"/sign-in"} locale={i18n.language} className={styles['link-container']}>
        <ButtonUI label={t('signin.button')} variant="text" startIcon={<IconLogin />} />
      </Link>
    )
  }
  const toggleDrawer = (open: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (event && event.type === 'keydown' && ['Tab', 'Shift'].includes((event as KeyboardEvent).key)) {
        return
      }

      setState(open)
    }

  return <header className={`${styles['header']} flex flex-row items-center m-4 py-0 px-4 md:py-2`}>
    <div className="flex basis-1/6 md:hidden">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        className="p-0"
      >
        <IconMenu2 />
      </IconButton>
      <Drawer
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Logo marginTop="1em" marginRight="3em" marginBottom="1em" marginLeft="3em" />
        <MenuSignIn variant="col" />
        {login && buildLoginButton()}
      </Drawer>
    </div>
    <div className="flex basis-4/6 justify-center items-center md:basis-4/6 md:justify-start">
      <Logo marginTop="0" marginLeft="0" />
      <div className="ml-8 hidden md:block">
        <MenuSignIn withIcon={false} />
      </div>
    </div>
    <div className="flex basis-1/6 md:basis-2/6 justify-end items-center">
      <Divider className="hidden md:inline" sx={ { margin: '.5em' } } orientation="vertical" flexItem />
      {login && (
        <span className="hidden md:inline">{buildLoginButton()}</span>
      )}
      <MenuLang anchorEl={anchorEl} handleClick={handleClickButton} />
    </div>
  </header>
}

export default SignInHeader
