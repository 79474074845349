import { i18n } from "i18next"
import { MenuUIItemPropsI } from "@lib-ui/molecules/MenuUI/interfaces"
import { IconArticle, IconCreditCard, IconHome2 } from "@tabler/icons-react"
import { Theme } from "@mui/material/styles"

export const menuList = (i18next: i18n, theme: Theme): Array<MenuUIItemPropsI> => ([
  {
    idx: 'home',
    label: `${i18next.t('menu.signIn.home')}`,
    link: i18next.language === 'fr-FR' ? 'https://www.allready.ai/fr/' : 'https://www.allready.ai/',
    icon: <IconHome2 color={theme.palette.primary.main} stroke={1.25} />,
  },
  {
    idx: 'resources',
    label: `${i18next.t('menu.signIn.resources')}`,
    link: i18next.language === 'fr-FR' ? 'https://www.allready.ai/fr/blog/' : 'https://www.allready.ai/blog/',
    icon: <IconArticle color={theme.palette.primary.main} stroke={1.25} />,
  },
  {
    idx: 'pricing',
    label: `${i18next.t('menu.signIn.pricing')}`,
    link: i18next.language === 'fr-FR' ? 'https://www.allready.ai/fr/tarif/' : 'https://www.allready.ai/pricing/',
    icon: <IconCreditCard color={theme.palette.primary.main} stroke={1.25} />,
  },
])
